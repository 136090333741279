// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyC-H6U5r1J5jpr3Fw8HZk8nxXClcmixj-k',
    authDomain: 'carlo-carcare.firebaseapp.com',
    projectId: 'carlo-carcare',
    storageBucket: 'carlo-carcare.appspot.com',
    messagingSenderId: '871580666127',
    appId: '1:871580666127:web:149d38409d45560359967a',
    measurementId: 'G-EGNP18E7GS',
  },
  /*****
  firebase: {
    apiKey: 'AIzaSyDLpj5rI53n623gRIWSK61jYeiMy1occbI',
    authDomain: 'carlo-carcare-dev.firebaseapp.com',
    projectId: 'carlo-carcare-dev',
    storageBucket: 'carlo-carcare-dev.appspot.com',
    messagingSenderId: '959110802565',
    appId: '1:959110802565:web:77e18f6ad88fd7c39c8dec',
    measurementId: 'G-3GERE7EVE5',
  },
  */
 

  // apiUrl_local: 'http://localhost:5000/carlo-carcare/us-central1/',
  apiUrl_orig: 'http://localhost:5000/carlo-carcare/us-central1/',
  apiUrl_dev: 'http://localhost:5000/carlo-carcare-dev/us-central1/',

  apiUrl: 'https://us-central1-carlo-carcare.cloudfunctions.net/',
  _apiUrl: 'http://localhost:5000/carlo-carcare-dev/us-central1/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
