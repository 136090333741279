import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import {
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  canActivate,
} from '@angular/fire/auth-guard';
import { AuthService } from './services/auth.service';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    ...canActivate(redirectLoggedInToHome),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('./pages/customers/customers.module').then(
        (m) => m.CustomersPageModule
      ),
    // canActivate:[AuthService]
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./pages/services/services.module').then(
        (m) => m.ServicesPageModule
      ),
    // canActivate:[AuthService]
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'vendors',
    loadChildren: () =>
      import('./pages/vendors/vendors.module').then((m) => m.VendorsPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'admins',
    loadChildren: () =>
      import('./pages/admins/admins.module').then((m) => m.AdminsPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'customerservice',
    loadChildren: () =>
      import('./pages/customerservice/customerservice.module').then(
        (m) => m.CustomerservicePageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'technicians',
    loadChildren: () =>
      import('./pages/technicians/technicians.module').then(
        (m) => m.TechniciansPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newservice',
    loadChildren: () =>
      import('./modals/newservice/newservice.module').then(
        (m) => m.NewservicePageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newadmin',
    loadChildren: () =>
      import('./modals/newadmin/newadmin.module').then(
        (m) => m.NewadminPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newagent',
    loadChildren: () =>
      import('./modals/newagent/newagent.module').then(
        (m) => m.NewagentPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newsubservice',
    loadChildren: () =>
      import('./modals/newsubservice/newsubservice.module').then(
        (m) => m.NewsubservicePageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newvendor',
    loadChildren: () =>
      import('./modals/newvendor/newvendor.module').then(
        (m) => m.NewvendorPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'assignservices',
    loadChildren: () =>
      import('./modals/assignservices/assignservices.module').then(
        (m) => m.AssignservicesPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newtechnician',
    loadChildren: () =>
      import('./modals/newtechnician/newtechnician.module').then(
        (m) => m.NewtechnicianPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'packages',
    loadChildren: () =>
      import('./pages/packages/packages.module').then(
        (m) => m.PackagesPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'vouchers',
    loadChildren: () =>
      import('./pages/vouchers/vouchers.module').then(
        (m) => m.VouchersPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newvoucher',
    loadChildren: () =>
      import('./modals/newvoucher/newvoucher.module').then(
        (m) => m.NewvoucherPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'areas',
    loadChildren: () =>
      import('./pages/areas/areas.module').then((m) => m.AreasPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newarea',
    loadChildren: () =>
      import('./modals/newarea/newarea.module').then(
        (m) => m.NewareaPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newpackage',
    loadChildren: () =>
      import('./modals/newpackage/newpackage.module').then(
        (m) => m.NewpackagePageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newvehicle',
    loadChildren: () =>
      import('./modals/newvehicle/newvehicle.module').then(
        (m) => m.NewvehiclePageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'vehicle',
    loadChildren: () =>
      import('./pages/vehicle/vehicle.module').then((m) => m.VehiclePageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'inquiries',
    loadChildren: () =>
      import('./pages/inquiries/inquiries.module').then(
        (m) => m.InquiriesPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'calendar',
    loadChildren: () =>
      import('./pages/calendar/calendar.module').then(
        (m) => m.CalendarPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'reschedule-order',
    loadChildren: () =>
      import('./modals/reschedule-order/reschedule-order.module').then(
        (m) => m.RescheduleOrderPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'assigntechnician',
    loadChildren: () =>
      import('./modals/assigntechnician/assigntechnician.module').then(
        (m) => m.AssigntechnicianPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'completed',
    loadChildren: () =>
      import('./pages/completed/completed.module').then(
        (m) => m.CompletedPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'inprocess',
    loadChildren: () =>
      import('./pages/inprocess/inprocess.module').then(
        (m) => m.InprocessPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'cancelled',
    loadChildren: () =>
      import('./pages/cancelled/cancelled.module').then(
        (m) => m.CancelledPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newinquiry',
    loadChildren: () =>
      import('./modals/newinquiry/newinquiry.module').then(
        (m) => m.NewinquiryPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newvehicleforcustomer',
    loadChildren: () =>
      import(
        './modals/newvehicleforcustomer/newvehicleforcustomer.module'
      ).then((m) => m.NewvehicleforcustomerPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'newaddressforcustomer',
    loadChildren: () =>
      import(
        './modals/newaddressforcustomer/newaddressforcustomer.module'
      ).then((m) => m.NewaddressforcustomerPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'tracking',
    loadChildren: () =>
      import('./pages/tracking/tracking.module').then(
        (m) => m.TrackingPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('./pages/customers/customers.module').then(
        (m) => m.CustomersPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'editinquiry',
    loadChildren: () =>
      import('./modals/editinquiry/editinquiry.module').then(
        (m) => m.EditinquiryPageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'edit-package',
    loadChildren: () =>
      import('./modals/edit-package/edit-package.module').then(
        (m) => m.EditPackagePageModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  // {
  //   path: 'packageinquiries',
  //   loadChildren: () =>
  //     import('./pages/package-inquiries/package-inquiries.module').then(
  //       (m) => m.PackageInquiriesPageModule
  //     ),
  //   ...canActivate(redirectUnauthorizedToLogin),
  // },
  {
    path: 'sample',
    loadChildren: () =>
      import('./pages/sample/sample.module').then((m) => m.SamplePageModule),
    // ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'newsample',
    loadChildren: () =>
      import('./modals/newsample/newsample.module').then(
        (m) => m.NewsamplePageModule
      ),
  },

  // {
  //   path: 'newcustomer',
  //   loadChildren: () =>
  //     import('./modals/newcustomer/newcustomer.module').then(
  //       (m) => m.NewcustomerPageModule
  //     ),
  // },
  // {
  //   path: 'newcustomer',
  //   loadChildren: () =>
  //     import('./modals/newcustomer/newcustomer.module').then(
  //       (m) => m.NewcustomerPageModule
  //     ),
  // },
  {
    path: 'editarea',
    loadChildren: () =>
      import('./modals/editarea/editarea.module').then(
        (m) => m.EditareaPageModule
      ),
  },
  {
    path: 'edittechnician',
    loadChildren: () =>
      import('./modals/edittechnician/edittechnician.module').then(
        (m) => m.EdittechnicianPageModule
      ),
  },
  {
    path: 'editvoucher',
    loadChildren: () =>
      import('./modals/editvoucher/editvoucher.module').then(
        (m) => m.EditvoucherPageModule
      ),
  },
  {
    path: 'package-inquiries',
    loadChildren: () => import('./pages/package-inquiries/package-inquiries.module').then( m => m.PackageInquiriesPageModule)
  },
];

@NgModule({
  // imports: [
  //   RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  // ],
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
