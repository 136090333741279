import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
// import { Storage } from '@ionic/storage';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  inProcess = null;
  completed = null;
  cancelled = null;

  public appPages = [
    {
      title: 'Home',
      url: 'home',
      icon: 'home',
      access: ['admin', 'vendor', 'technician'],
    },
    {
      title: 'Tracking',
      url: 'tracking',
      icon: 'locate',
      access: ['admin', 'vendor'],
    },
    {
      title: 'New Inquiries',
      url: 'inquiries',
      icon: 'receipt',
      access: ['admin', 'vendor'],
    },
    {
      title: 'In Process',
      url: 'inprocess',
      icon: 'git-compare',
      access: ['admin'],
    },
    {
      title: 'Completed',
      url: 'completed',
      icon: 'checkmark-done',
      access: ['admin'],
    },
    {
      title: 'Cancelled',
      url: 'cancelled',
      icon: 'close-circle',
      access: ['admin'],
    },
    {
      title: 'Package Customers',
      url: 'package-inquiries',
      icon: 'card',
      access: ['admin', 'vendor'],
    },
    // { title: 'Calendar', url: 'calendar', icon: 'calendar' , access: ["admin", "vendor"] },
    {
      title: 'Customers',
      url: 'customers',
      icon: 'people',
      access: ['admin', 'vendor'],
    },
    {
      title: 'Services',
      url: 'services',
      icon: 'settings',
      access: ['admin', 'vendor'],
    },
    {
      title: 'Packages',
      url: 'packages',
      icon: 'pricetags',
      access: ['admin'],
    },
    { title: 'Vouchers', url: 'vouchers', icon: 'bookmark', access: ['admin'] },
    { title: 'Vendors', url: 'vendors', icon: 'list', access: ['admin'] },
    { title: 'Vehicles', url: 'vehicle', icon: 'car-sport', access: ['admin'] },
    { title: 'Admins', url: 'admins', icon: 'people', access: ['admin'] },
    {
      title: 'Customer Service',
      url: 'customerservice',
      icon: 'call',
      access: ['admin'],
    },
    {
      title: 'Technicians',
      url: 'technicians',
      icon: 'settings',
      access: ['admin', 'vendor'],
    },
    {
      title: 'Areas',
      url: 'areas',
      icon: 'location',
      access: ['admin', 'vendor'],
    },
    // { title: 'My Sample', url: 'sample', icon: 'heart' , access: ["admin" ] },
  ];
  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private authService: AuthService,
    private router: Router,
    private http: HttpClient // private storage: Storage
  ) {}

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Warning!',
      message: 'You will be logged out',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Okay',
          id: 'confirm-button',
          handler: () => {
            this.logout();
            // console.log('Confirm Okay');
          },
        },
      ],
    });

    await alert.present();
  }

  async logout() {
    const loading = await this.loadingController.create();
    await loading.present();

    await this.authService
      .logout()
      .then(() => {
        loading.dismiss();
        this.router.navigateByUrl('/login', { replaceUrl: true });
      })
      .catch((err) => {
        loading.dismiss();
        this.showAlert('Logout failed', err.message);
      });
  }

  async showAlert(header, message) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
    });
    await alert.present();
  }

  async ngOnInit() {
    // await this.authService.isCurrentUserVendor();
    await this.authService.getUserRole();

    // this.http.get('https://us-central1-carlo-carcare.cloudfunctions.net/ordersModule/orders/getAllPending').subscribe((res:[])=>{
    //   this.inProcess= res.length;
    // })
    // this.http
    // .get(
    //   'https://us-central1-carlo-carcare.cloudfunctions.net/ordersModule/orders/getAllcompleted'
    // )
    // .subscribe(async (res: any) => {
    //   this.completed= res.length;
    // });
    // this.http
    // .get(
    //   'https://us-central1-carlo-carcare.cloudfunctions.net/ordersModule/orders/getallcancelled'
    // )
    // .subscribe(async (res: any) => {
    //   // console.log(res);
    //   this.cancelled = res.length;
    // });
  }

  updateC() {
    // alert('test')
    // this.ngOnInit();
    // window.location.reload();
  }

  gotoHome() {
    // window.location.href = '/';
    this.router.navigateByUrl('/');
  }
}
