import { Injectable } from '@angular/core';
import {
  Auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  User,
} from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { returnDate } from '@mobiscroll/angular/dist/js/core/util/datetime';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isVendorBool: boolean;
  userId: string = null;
  userRole: string = null;
  constructor(
    private auth: Auth,
    private afAuth: AngularFireAuth,
    private nav: NavController
  ) {}

  async register({ email, password }) {
    try {
      const user = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      return user;
    } catch (e) {
      return null;
    }
  }

  async login({ email, password }) {
    try {
      const user = await signInWithEmailAndPassword(this.auth, email, password);
      return user;
    } catch (e) {
      return null;
    }
  }

  async canActivate() {
    const user: any = await this.afAuth.authState.pipe(first()).toPromise();
    console.log(user);
    if (user != null) {
      if (user?.displayName == 'vendor') {
        alert('u r vendor, access denied');
        return false;
      }
      return true;
    }
    this.nav.navigateRoot('login');
    return false;

    // try {
    //   const user = await signInWithEmailAndPassword(this.auth, email, password);
    //    if(user.user.displayName=='vendor'){
    //    alert('u r vendor, access denied')
    //    return null;

    //    }
    //   return user;
    // } catch (e) {
    //   return null;
    // }
  }

  logout() {
    return signOut(this.auth);
  }
 
  async getUserRole() {
    const user: User = await this.afAuth.authState.pipe(first()).toPromise();
    this.userRole = user?.displayName ? user?.displayName : 'admin';
    this.userId = user?.uid;
    // console.log('user?>>>>', user?.uid, user);
  }
 
}
